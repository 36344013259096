import { getAppBasePath } from 'utils/config/util';

export const APP_PATHS = {
  basePage: getAppBasePath() + '/',
  accountNotEligible: getAppBasePath() + '/not-eligible',
  enterAccountNumber: getAppBasePath() + '/enter',
  enterAccountNumberError: getAppBasePath() + '/error',
  walledGarden: getAppBasePath() + '/walled-garden',
};

export const COMCAST_BUSINESS_PATHS = {
  account: 'https://business.comcast.com/account/',
  home: 'https://business.comcast.com/',
  helpAndSupport: 'http://businesshelp.comcast.com/help-and-support',
};

export const EXTERNAL_PATHS = {
  gettingStartedGuide:
    'https://secure.xfinity.com/anon.comcastonline2/support/helpandsupport/businessclass/help_articles/HOW4613/HOW4613_Self_Install_Guide.pdf',
};
