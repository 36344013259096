import { useContext, useEffect } from 'react';
import { dispatchCustomEvent } from 'bsd-ui-utils/tracking';

// context
import AppContext from 'context/AppContext';

export default function useTrackPage(screenName) {
  const { sessionId } = useContext(AppContext);
  useEffect(() => {
    const detail = {
      page: {
        pageInfo: {
          screenName,
        },
        codebase: {
          releaseVersion: `${process.env.REACT_APP_UI_VERSION}`,
        },
      },
      siteInfo: {
        sessionID: sessionId,
        visitorID: sessionId,
      },
    };
    dispatchCustomEvent(detail, true);
  }, [screenName, sessionId]);
}
