import { v4 as uuid } from 'uuid';

import { browser } from 'bsd-ui-utils';

import config from 'config';

export const formatResponse = (response) => {
  const responseData = response?.data?.data || response?.data;

  return {
    body: responseData || response?.error || {},
    messages: response?.data?.messages,
    status: response?.status || null,
  };
};

export function getErrorCodes(response) {
  return response?.messages?.map((message) => message.code) || [];
}

export const updateSessionId = () => {
  const cookieName = 'cb_ucid';
  const sessionIdCookie = browser.getCookie(cookieName);
  const sessionId = sessionIdCookie || uuid();
  const date = new Date(
    Date.now() + config.sessionDurationInMinutes * 60 * 1000
  );
  document.cookie = `${cookieName}=${sessionId}; expires=${date.toGMTString()}; domain=comcast.com`;
  return sessionId;
};
