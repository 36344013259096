import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Spinner, Sprite as UIKitSprite } from 'bsd-react-ui-kit';

import { updateSessionId } from 'utils/util';

import config from 'config';
import { fidoV2 as fido, unauthenticatedLogger } from 'bsd-ui-utils';
import Layout from 'containers/Layout/Layout';
import Sprite from 'components/Sprite/Sprite';

import AppContext from 'context/AppContext';

// constants
import routes from 'routes';

export default function App() {
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    if (sessionId) {
      const { fidoConfig, loggerConfig } = config;
      fidoConfig.headers['tracking-id'] = sessionId;
      loggerConfig.visitorSessionId = sessionId;
      unauthenticatedLogger.config(loggerConfig);
      fido.init({
        ...config.fidoConfig,
        loggingProvider: unauthenticatedLogger,
      });
    }
  }, [sessionId]);

  useEffect(() => {
    if (!sessionId) {
      const updatedSessionId = updateSessionId();
      setSessionId(updatedSessionId);
    }
  }, [sessionId, setSessionId]);

  const renderLayout = (route, props) => {
    const { component, pageTitle, isWalledGarden } = route;

    return (
      <Layout
        component={component}
        isWalledGarden={Boolean(isWalledGarden)}
        pageTitle={pageTitle}
        {...props}
      />
    );
  };

  return (
    <div className="bsd-main-content min-content-height" id="main" role="main">
      <AppContext.Provider value={{ sessionId }}>
        <UIKitSprite>
          <Sprite />
        </UIKitSprite>
        <Suspense fallback={<Spinner show />}>
          <Switch>
            {routes.map((route) => {
              const { exact, path } = route;

              return (
                <Route
                  exact={exact}
                  key={path}
                  path={path}
                  render={(props) => renderLayout(route, props)}
                />
              );
            })}
          </Switch>
        </Suspense>
      </AppContext.Provider>
    </div>
  );
}
